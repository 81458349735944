/* -------------------------------------------------------------
//  Cards
// -----------------------------------------------------------*/
.card__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1500px;
}
.card__item {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  max-width: 500px;
  width: 100%;
  .card__image img {
    margin: 0;
  }
  .card__content {
    flex: 1;
    padding: 40px 0;
    background-color: $light-grey;
  }
  .card__name {
    font-size: 1.625rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .card__desc {
    font-size: 1.125rem;
    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
.bg-color-grey .card__content {
  background-color: $white;
}
@media only screen and (min-width: 768px) {
  .card__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
  }
  .card__item {
    padding-bottom: 10px;
    width: calc(50% - 14px);
  }
  .card__image img {
    margin-bottom: 50px;
  }
  .glide__slide .card__item {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .card__item {
    padding-bottom: 20px;
    width: calc(25% - 21px);
  }
  .card__image img {
    margin-bottom: 55px;
  }
  .glide__slide .card__item {
    width: 100%;
  }
}
