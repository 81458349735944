/* -------------------------------------------------------------
//  Typo
// -----------------------------------------------------------*/

h1 {
  color: $color-primary;
  font-family: 'Roboto Condensed', Helvetica, Arial;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.16em;
  margin-top: 2.625rem;
  margin-bottom: 2.333rem;
}

h2 {
  color: $color-primary;
  font-family: 'Roboto Condensed', Helvetica, Arial;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1em;
  margin-top: 2.625rem;
  margin-bottom: 1.375rem;
}

h3 {
  color: $color-primary;
  font-family: 'Roboto Condensed', Helvetica, Arial;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.171875em;
  margin-top: 2.625rem;
  margin-bottom: 1.375rem;
}

h4 {
  color: $color-primary;
  font-family: 'Roboto Condensed', Helvetica, Arial;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.26em;
  margin-top: 2.625rem;
  margin-bottom: 1.375rem;
}

h5 {
  color: $color-primary;
  font-family: 'Roboto Condensed', Helvetica, Arial;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.3em;
  margin-top: 2.625rem;
  margin-bottom: 1.375rem;
}

h6 {
  color: $color-primary;
  font-family: 'Roboto Condensed', Helvetica, Arial;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 2.625rem;
  margin-bottom: 1.375rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 6rem;
  }
  h2 {
    font-size: 4.6875rem;
  }
  h3 {
    font-size: 4rem;
  }
  h4 {
    font-size: 1.875rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  h6 {
    font-size: 1.5rem;
  }
}

.col-12 > h2,
.col-12 > h3,
.col-12 > h4,
.col-12 > h5,
.col-12 > h6 {
  margin-top: 0; // resets margin of firt headline
}
