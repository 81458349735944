/* -------------------------------------------------------------
//  Basics
// -----------------------------------------------------------*/
body {
  background-color: $white;
  color: $base-text-color;
  font-family: 'Roboto', Helvetica, Arial;
  font-weight: normal;
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  margin: 0;
  padding: 0;

  &.active {
    overflow: hidden;
  }
}
main {
}
