/* -------------------------------------------------------------
//  Button
// -----------------------------------------------------------*/
.btn {
  cursor: pointer;
  color: $white;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 400;
  outline: none;
  padding: 8px 20px;
  text-decoration: none;
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -ms-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;

  &:hover {
    text-decoration: none;
  }
}
.btn--primary {
  background: linear-gradient(to right, $yellow 50%, $color-primary 50%) no-repeat scroll right bottom / 210% 100%;
  border: none;
  color: $white;
}

.btn--primary:hover,
.btn--primary:focus {
  background-position: left bottom !important;
  color: $white;
  text-decoration: none;
}
.btn--secondary {
  background-color: $color-secondary;
  border: 2px solid $color-secondary;
  color: $color-primary !important;
}
.btn--secondary:hover,
.btn--secondary:focus {
  background-color: $color-secondary;
  border: 2px solid $color-secondary-dark;
  text-decoration: none;
}

.mouse {
  border: 2px solid $white;
  border-radius: 14px;
  display: block;
  height: 45px;
  position: absolute;
  bottom: 68px;
  left: 50%;
  margin-left: -11px;
  width: 24px;
}
.mouse::before {
  content: '';
  width: 3px;
  height: 8px;
  position: absolute;
  top: 6px;
  background: $white;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  opacity: 1;
  animation: mouse 2s infinite;
}
.mouse.no-animation::before {
  animation: none;
}

@keyframes mouse {
  from {
    opacity: 1;
    top: 6px;
  }
  to {
    opacity: 0.3;
    top: 35px;
  }
}
