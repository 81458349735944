/* -------------------------------------------------------------
//  Slider
// -----------------------------------------------------------*/
.glide__track {
  overflow: hidden;
  .glide__slides {
    display: flex;
    margin: 0;
    padding: 0;
    .glide__slide {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
}
.glide__bullets {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style: none;
  z-index: 2;
}
.glide__bullet {
  background-color: $logo-light-blue;
  width: 10px;
  height: 10px;
  padding: 0;
  border-radius: 50%;
  border: none;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  line-height: 0;
  margin: 0 8px;
}
.glide__bullet[class*='active'] {
  background-color: $color-primary;
}
.glide__controls {
  align-items: center;
  display: flex;
  justify-content: center;
  .glide__arrow {
    cursor: pointer;
    font-family: 'ahoi';
    padding: 0;
    margin: 0;
    border: none;
    color: $color-primary;
    background: transparent;
    font-size: 2.8rem;
  }
  .glide__arrow--left {
    // transform: rotate(90deg);
    margin-right: 10px;
  }
  .glide__arrow--right {
    transform: rotate(-180deg);
    margin-left: 10px;
  }
}
