/* -------------------------------------------------------------
//  Widgets
// -----------------------------------------------------------*/
#mm-microwidget {
  &.cleanslate {
    right: 3px !important;
    width:147px !important;
    transform: scale(0.75) !important;
    transform-origin: 100% 100% !important;
  }
}