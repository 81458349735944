p {
  font-size: 1.125rem;
  line-height: 1.55em;
  margin-bottom: 12px;
}

p.lead {
  color: $color-primary;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5em;
}

p.small,
small {
  margin-bottom: $element-margin-bottom;
}
strong {
  font-weight: 700;
}

.col-12 > p {
  margin-top: 0; // resets margin of firt P
}

.ce_text {
  margin: 0 auto;
  margin-bottom: 3rem;
  max-width: 900px;
  padding: 0 25px;
}

.margin_first_element .ce_text > h2 {
  margin-top: 0; // resets margin of firt P
}

@media only screen and (min-width: 992px) {
  .margin_first_element {
    margin-bottom: 2.625rem;
  }
}
