/* -------------------------------------------------------------
//  Accordion
// -----------------------------------------------------------*/
.Accordion {
  margin: 0;
  margin-bottom: $element-margin-bottom;
  padding: 0;
}
.Accordion h3 {
  margin: 0;
  padding: 0;
}
.Accordion.focus {
  border-color: $color-black-40;
}
.Accordion > * + * {
  border-top: 1px solid $color-black-40;
}
.Accordion-trigger {
  background: none;
  border-style: none;
  color: $black;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 1em 4em 1em 1.5em;
  position: relative;
  text-align: left;
  width: 100%;
  outline: none;
}
.Accordion-trigger:focus,
.Accordion-trigger:hover {
  background: $color-black-60;
}
.Accordion button::-moz-focus-inner {
  border: 0;
}
.Accordion-title {
  display: block;
  pointer-events: none;
  border: transparent 2px solid;
  border-radius: 5px;
  padding: 0.25em;
  outline: none;
}
.Accordion-trigger:focus .Accordion-title {
  /*border-color: hsl(216, 94%, 73%);*/
}
.Accordion-icon {
  border: 2px solid $color-primary;
  border-radius: 50%;
  height: 24px;
  pointer-events: none;
  position: absolute;
  right: 26px;
  top: 20px;
  width: 24px;
  speak: none;
}
.Accordion-icon::before,
.Accordion-icon::after {
  background-color: $color-primary;
  content: '';
  display: block;
  height: 2px;
  width: 16px;
  position: absolute;
  top: 11px;
  left: 4px;
}
.Accordion-icon::before {
  transform: rotate(90deg);
}
.Accordion-trigger:focus .Accordion-icon,
.Accordion-trigger:hover .Accordion-icon {
}
.Accordion-trigger[aria-expanded='true'] .Accordion-icon {
  speak: none;
}
.Accordion-trigger[aria-expanded='true'] .Accordion-icon::before {
  display: none;
}
.Accordion-panel {
  margin: 0;
  padding: 1em 1.5em;
}
.Accordion-panel[hidden] {
  display: none;
}
.Accordion-panel ul li {
  margin-bottom: 1em;
  padding-left: 30px;
}
@media only screen and (min-width: 576px) {
  .Accordion-icon {
    top: 18px;
  }
}
