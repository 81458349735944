/* -------------------------------------------------------------
//  Section
// -----------------------------------------------------------*/
.section {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.section::before,
.section::after {
  content: '';
  display: block;
}

.section::before {
  margin-bottom: $section-margin-top;
}

.section::after {
  margin-top: $section-margin-bottom;
}

.section--dark {
  background-color: $color-black-80;
}
.section--white {
  background-color: $color-light;
}
.section-padding-none {
  border: none;
}
.section-padding-none::before,
.section-padding-none::after {
  margin: 0;
}
.section-padding-top::after {
  margin: 0;
}
.section-padding-bottom::before {
  margin: 0;
}
.inside.container-fluid {
  padding: 0;
}
.ce_gallery.first.block {
  height: 500px;
}
.bg-color-grey {
  background-color: $light-grey;
}
.bg-image-top-right {
  background-position: right top;
  background-repeat: no-repeat;
}
.bg-image-bottom-left {
  background-position: left bottom;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
}

.container-intend {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
}
@media (min-width: 576px) {
  .container-intend {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-intend {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-intend {
    max-width: 900px;
  }
}
