/* -------------------------------------------------------------
//  Events
// -----------------------------------------------------------*/
.eventlist {
  margin: 0;
  padding: 0;
}
.eventlist__item {
  margin: 0;
  margin-top: 60px;
  padding: 0;
  list-style: none;
}
.eventlist__imagewrapper {
  position: relative;
}
.eventlist__image {
  margin-bottom: 16px;
}
.eventlist_date {
  align-items: center;
  background-color: $color-secondary-dark;
  color: $white;
  display: flex;
  flex-direction: column;
  height: 76px;
  justify-content: center;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 75px;

  span {
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  span.large {
    font-size: 23px;
    font-weight: 700;
    line-height: 28px;
  }
}
.eventlist_date--alternative {
  flex: none;
  position: relative;
}
.eventlist__datewrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 26px;

  .eventlist__headline {
    padding-top: 10px;
    padding-left: 19px;
  }
}
.eventlist_time {
  color: $color-black-70;
  display: block;
  margin-bottom: 5px;
}
.eventlist_time + p {
  margin-top: 0;
}
.eventlist__headline {
  margin-top: 0;
  margin-bottom: 5px;
}
.eventdetail_headline {
  margin-top: 0;
}
