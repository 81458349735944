/* -------------------------------------------------------------
//  List
// -----------------------------------------------------------*/
.ce_text ul {
  margin-top: 1.5rem;
  margin-bottom: $element-margin-bottom;
  padding-left: 1.4em;

  li {
    padding-bottom: 0.3em;
  }
}
