/* -------------------------------------------------------------
//  Kontaktformular
// -----------------------------------------------------------*/
.contact_form .text,
.contact_form .textarea {
  border: 0 none;
  border-bottom: 1px solid #b7b7b7;
  color: #000;
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 18px;
  line-height: 1.3em;
  margin-bottom: $element-margin-bottom;
  padding: 21px 17px 26px 17px;
  width: 100%;
}
.contact_form .textarea {
  min-height: 160px;
}
.contact_form .widget-submit {
  text-align: center;
  padding-top: 10px;
}
.contact_form .checkbox {
  height: 20px;
  width: 20px;
  vertical-align: sub;
}
.contact_form ::-webkit-input-placeholder {
  color: #000000;
}
.contact_form ::-moz-placeholder {
  color: #000000;
}
.contact_form :-ms-input-placeholder {
  color: #000000;
}
.contact_form :-moz-placeholder {
  color: #000000;
}
.widget-text label,
.widget-textarea label {
  position: absolute;
  top: 21px;
  left: 17px;
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.widget {
  position: relative;
}
.contact_form .widget.active label {
  font-size: 0.8em;
  top: 1px;
}
.contactperson__item {
  position: relative;
  max-width: 340px;
  margin: 0 auto;
  margin-bottom: 1em;
}
