/* -------------------------------------------------------------
//  Stage
// -----------------------------------------------------------*/
.full_stage {
  height: calc(100vh - 65px);
  position: relative;
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.full_stage_background {
  background: #124380;
}
.full_stage_wrapper {
  box-sizing: border-box;
  background-color: $white;
  padding: 20px;
  opacity: 0.8;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  .full_stage_wrapper {
    opacity: 1;
  }
}
.full_stage_box h2 {
  margin-top: 1.375rem;
  hyphens:auto;
}
.full_stage_box h4 {
  margin: 0;
}

@media only screen and (min-width: 992px) and (min-height: 600px) {
  .full_stage {
    height: calc(100vh - 100px);
  }
  .full_stage_wrapper {
    width: 47.5%;
    right: 0;
    left: auto;
    bottom: 100px;
    padding: 35px 75px;
  }
}


.video_wrapper_desktop {
  display: none;
}
.video_wrapper_desktop,
.video_wrapper_mobile {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

@media (min-aspect-ratio: 2 / 3) {
  .video_wrapper_desktop {
    display: block;
  }
  .video_wrapper_mobile {
    display: none;
  }
}