/* -------------------------------------------------------------
//  Links
// -----------------------------------------------------------*/
.link {
  text-decoration: underline;
  padding-left: 1em;
  position: relative;

  &.link--footer,
  &.link--footer:visited {
    font-weight: 300;
    text-decoration: none;
    padding-left: 0;
  }
  &.link--footer:hover,
  &.link--footer:focus {
    cursor: pointer;
    text-decoration: underline;
  }
  &.link--white {
    color: $white;
  }
  &.link--white:focus {
    outline: 3px solid $white;
  }
}
a {
  color: $color-primary-dark;
}
a:hover,
a:focus {
  color: $color-primary;
}
a:focus {
  outline: 3px solid $color-primary;
}
a:active {
  outline: none;
}
footer a {
  color: $white;
}
footer a:hover {
  color: $white;
}
footer a:focus {
  color: $white;
  outline: 3px solid $white;
}

.skip-nav-link {
  background-color: $white;
  border: 2px solid $color-primary-dark;
  border-radius: 3px;
  color: $color-primary-dark;
  left: 10px;
  padding: 0.5rem 1rem;
  position: absolute;
  top: 10px;
  transform: translateX(-120%);
  transition: transform 250ms ease-in;
}
.skip-nav-link:focus {
  transform: translateX(0%);
}
