/* -------------------------------------------------------------
//  Navigation
// -----------------------------------------------------------*/
.navwrapper {
  background: #fff;
  display: block;
  flex-direction: column;
  right: -110%;
  padding: 25px;
  padding-top: 49px;
  position: fixed;
  top: 0;
  width: 100vw;
  max-width: 250px;
  height: 100vh;
  overflow: auto;
  z-index: 5;

  -webkit-transition: right $transition--slow;
  -moz-transition: right $transition--slow;
  -ms-transition: right $transition--slow;
  -o-transition: right $transition--slow;
  transition: right $transition--slow;
}
.navwrapper.active {
  right: 0;
  .navlist__item {
    padding-left: 20px;
  }
  .navlist__item--contact {
    background: $color-secondary-dark;
    padding-top: 24px;
    padding-right: 40px;
    line-height: 34px !important;
  }
}
.navcontact {
  color: $color-black-80;
  display: flex;
  position: absolute;
  right: 50;

  & > span {
    align-items: end;
    display: grid;
    height: 20px;
    width: 26px;
    font-size: 24px;
    padding: 4px;
  }
}

.navbar {
  box-shadow: 0px 0.222rem 0.444rem rgb(0 0 0 / 15%);
  -moz-box-shadow: 0px 0.222rem 0.444rem rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0px 0.222rem 0.444rem rgb(0 0 0 / 15%);
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  height: 45px;
}
.navbutton {
  border-left: 30px solid $white;
  cursor: pointer;
  height: 22px;
  position: relative;
  margin-left: auto;
  width: 22px;
  z-index: 15;
  -webkit-transition: top $transition--slow;
  -moz-transition: top $transition--slow;
  -ms-transition: top $transition--slow;
  -o-transition: top $transition--slow;
  transition: top $transition--slow;
}

/* Mainnavigation */
.navlist {
  padding: 0;
}
.navlist__item {
  border-bottom: 1px solid $color-black-40;
  font-size: 1.11em;
  list-style: none;
}
.navlist__item:last-of-type {
  border-bottom: 0 none;
}
.navlist__item.active {
  font-weight: $font-xlarge-bold-font-weight;
}
.navlist__link {
  color: $base-text-color !important;
  display: block;
  font-size: rem($font-xlarge-font-size);
  font-weight: $font-xlarge-font-weight;
  text-decoration: none;
  margin: 0;
  padding: 15px;
  position: relative;
}
.navlist__link:hover,
.navlist__link:focus {
  text-decoration: none;
}
.navlist__link.active,
.navlist__link.trail {
  font-weight: $font-xlarge-bold-font-weight;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .navwrapper {
    padding-top: 84px;
  }
}

/* Footernavigation */
.navbar.navbar--footer {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
}
.navbar--footer .navlist {
  background: transparent;
}
.navbar--footer .navlist__item {
  border: 0 none;
  font-size: 1em;
}
.navbar--footer .navlist__link {
  background: transparent;
  color: $white !important;
  font-size: 1em;
  padding: 0 0 10px 0;
}
.navbar--footer .navlist__link:hover,
.navbar--footer .navlist__link:focus {
  text-decoration: underline;
}
.sociallist {
  margin: 0;
  padding: 0;
}
.sociallist__item {
  display: inline-block;
  font-family: 'ahoi' !important;
  font-size: 25px;
  margin: 0 10px;
  padding: 0;
  list-style-type: none;
}
.sociallist__item a {
  text-decoration: none;
  transition: color $transition--middle;
}
.sociallist__item a:hover {
  color: #e1b050;
}


@media only screen and (min-width: 768px) {
  .sociallist {
    margin-left: -9px;
  }
}
a.navlist__link.sibling.last.number {
  letter-spacing: normal;
  margin: 0;
  &:hover {
    letter-spacing: normal;
  }
}

@media only screen and (min-width: 992px) {
  .sociallist {
    margin-left: -15px;
  }
  .header-phone {
    margin-right: 47px;
  }
}

.menu-burger-wrapper {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
}

.nav_icon {
  font-size: 0;
  font-weight: 400;
  margin-left: 48px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  z-index: 20;
  > div {
    display: none;
  }
  &.active > div {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    display: block;
    padding: 20px;
    font-size: $font-small-font-size;
    background-color: white;
  }
}
.nav_icon::before {
  font-size: 20px;
  left: -28px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1px;
  vertical-align: text-top;
}
.nav_icon.icon-phone {
  display: none;
}
@media only screen and (min-width: 768px) {
  .nav_icon {
    font-size: 1rem;
    &.active > div {
      position: absolute;
      left: -20px;
      top: 67px;
      width: 300px;
    }
  }
  .nav_icon.icon-shop {
    > div ul {
      margin: 0;
      padding: 0;
      width: 220px;
      list-style-type: none;
    }
  }
  .nav_icon.icon-phone {
    display: block;
  }
  .nav_icon.icon-phone::before {
    font-size: 24px;
    left: -28px;
    top: 0;
  }
}

/* Burgerbutton */
.line {
  background-color: $base-text-color;
  display: block;
  height: 2px;
  position: absolute;
  width: 22px;
}
.line--top {
  left: 0;
  top: 4px;
}
.line--middle {
  left: 0;
  top: 11px;
}
.line--bottom {
  left: 0px;
  top: 18px;
}
/* show Menu */
.navbutton.active .line--top {
  -webkit-animation: line-top-animation 400ms 0s 1 forwards;
  -moz-animation: line-top-animation 400ms 0s 1 forwards;
  -o-animation: line-top-animation 400ms 0s 1 forwards;
  animation: line-top-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
@-moz-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
@-o-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
@keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
.navbutton.active .line--bottom {
  -webkit-animation: line-bottom-animation 400ms 0s 1 forwards;
  -moz-animation: line-bottom-animation 400ms 0s 1 forwards;
  -o-animation: line-bottom-animation 400ms 0s 1 forwards;
  animation: line-bottom-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
@-moz-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
@-o-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
@keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
.navbutton.active .line--middle {
  -webkit-animation: line-middle-animation 400ms 0s 1 forwards;
  -moz-animation: line-middle-animation 400ms 0s 1 forwards;
  -o-animation: line-middle-animation 400ms 0s 1 forwards;
  animation: line-middle-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@-moz-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@-o-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
/* hide Menu */
.navbutton .line--top {
  -webkit-animation: line-top-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-top-animation-back 400ms 0s 1 forwards;
  -o-animation: line-top-animation-back 400ms 0s 1 forwards;
  animation: line-top-animation-back 400ms 0s 1 forwards;
}
@-webkit-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
@-moz-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
@-o-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
@keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
.navbutton .line--bottom {
  -webkit-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  -o-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  animation: line-bottom-animation-back 400ms 0s 1 forwards;
}
@-webkit-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
@-moz-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
@-o-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
@keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
.navbutton .line--middle {
  -webkit-animation: line-middle-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-center-animation-back 400ms 0s 1 forwards;
  -o-animation: line-center-animation-back 400ms 0s 1 forwards;
  animation: line-center-animation-back 400ms 0s 1 forwards;
}
@-webkit-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@-moz-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@-o-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@media only screen and (min-width: 768px) {
  .navbar {
    height: 80px;
  }
  .navbar.navbar--footer {
    padding: 0;
  }
}
.footernavigation {
  display: flex;
}

.footer__copyright {
  margin: 1.25rem 0 0;
}

.mod_customnav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    margin: 0 0.625rem;
  }
  &.nav-footer-right {
    margin: 1.25rem 0 0;
  }
}
@media only screen and (min-width: 576px) {
  .mod_customnav {
    flex-direction: row;
  }
}
@media only screen and (min-width: 992px) {
  .navbutton::before {
    content: 'Menü';
    display: block;
    margin-left: -48px;
    font-weight: 400;
  }
  .mod_customnav.nav-footer-left {
    justify-content: flex-start;
  }
  .mod_customnav.nav-footer-right {
    justify-content: flex-end;
    margin: 0;
  }
}
