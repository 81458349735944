/* -------------------------------------------------------------
//  Teaser
// -----------------------------------------------------------*/
.teaser {
  margin-bottom: 2rem;
}
.teaser h4 {
  font-weight: 700;
  margin-top: 0.9rem;
}
