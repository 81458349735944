/* -------------------------------------------------------------
//  Images
// -----------------------------------------------------------*/
img {
  height: auto;
  margin-bottom: $element-margin-bottom;
  max-width: 100%;
}
.image_container {
  margin: 0;
}

.logo {
  display: block;
  margin: 0;
  width: 50px;

  img {
    margin: 0;
  }
}

.logo_footer {
  width: 230px;
}

@media only screen and (min-width: 768px) {
  .logo {
    width: 89px;
  }
}
