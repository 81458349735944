// Do not edit directly
// Generated on Mon, 08 Nov 2021 18:58:31 GMT

$color-black: #000000;
$color-black-90: #111827;
$color-black-80: #1f2937;
$color-black-70: #6b7280;
$color-black-60: #8f90a6;
$color-black-50: #d1d5db;
$color-black-40: #e4e4eb;
$color-black-30: #ebebf0;
$color-black-20: #f2f2f5;
$color-black-10: #fafafc;
$color-light: #ffffff;
$color-primary-dark: #16292b;
$color-primary: #285756;
$color-primary-bg: #92abd8;
$color-secondary-dark: #25418e;
$color-secondary: #4a5ba1;
$color-secondary-bg: #767eb2;
$color-accent: #178249;
$color-accent-dark: #035e24;
$color-accent-bg: #48a381;
$color-danger-dark: #e53535;
$color-danger: #ff3b3b;
$color-danger-bg: #ff8080;
$color-info-dark: #00b7c4;
$color-info: #00cfde;
$color-info-bg: #a9eff2;
$color-success-dark: #05a660;
$color-success: #06c270;
$color-success-bg: #57eba1;
$color-warning-dark: #e5b800;
$color-warning: #ffcc00;
$color-warning-bg: #fded72;
$font-xxlarge-bold-font-size: 28;
$font-xxlarge-bold-text-decoration: none;
$font-xxlarge-bold-font-family: Mulish;
$font-xxlarge-bold-font-weight: 700;
$font-xxlarge-bold-font-style: normal;
$font-xxlarge-bold-font-stretch: normal;
$font-xxlarge-bold-font-style-old: Bold;
$font-xxlarge-bold-letter-spacing: 0;
$font-xxlarge-bold-line-height: 28;
$font-xxlarge-bold-paragraph-indent: 0;
$font-xxlarge-bold-paragraph-spacing: 0;
$font-xxlarge-bold-text-case: none;
$font-xxlarge-font-size: 28;
$font-xxlarge-text-decoration: none;
$font-xxlarge-font-family: Mulish;
$font-xxlarge-font-weight: 700;
$font-xxlarge-font-style: normal;
$font-xxlarge-font-stretch: normal;
$font-xxlarge-font-style-old: Bold;
$font-xxlarge-letter-spacing: 0;
$font-xxlarge-line-height: 28;
$font-xxlarge-paragraph-indent: 0;
$font-xxlarge-paragraph-spacing: 0;
$font-xxlarge-text-case: none;
$font-xlarge-bold-font-size: 21;
$font-xlarge-bold-text-decoration: none;
$font-xlarge-bold-font-family: Jost;
$font-xlarge-bold-font-weight: 600;
$font-xlarge-bold-font-style: normal;
$font-xlarge-bold-font-stretch: normal;
$font-xlarge-bold-font-style-old: SemiBold;
$font-xlarge-bold-letter-spacing: 0;
$font-xlarge-bold-line-height: 28;
$font-xlarge-bold-paragraph-indent: 0;
$font-xlarge-bold-paragraph-spacing: 0;
$font-xlarge-bold-text-case: none;
$font-xlarge-font-size: 20;
$font-xlarge-text-decoration: none;
$font-xlarge-font-family: Mulish;
$font-xlarge-font-weight: 400;
$font-xlarge-font-style: normal;
$font-xlarge-font-stretch: normal;
$font-xlarge-font-style-old: Regular;
$font-xlarge-letter-spacing: 0;
$font-xlarge-line-height: 32;
$font-xlarge-paragraph-indent: 0;
$font-xlarge-paragraph-spacing: 0;
$font-xlarge-text-case: none;
$font-large-bold-font-size: 20;
$font-large-bold-text-decoration: none;
$font-large-bold-font-family: Mulish;
$font-large-bold-font-weight: 700;
$font-large-bold-font-style: normal;
$font-large-bold-font-stretch: normal;
$font-large-bold-font-style-old: Bold;
$font-large-bold-letter-spacing: 0;
$font-large-bold-line-height: 32;
$font-large-bold-paragraph-indent: 0;
$font-large-bold-paragraph-spacing: 0;
$font-large-bold-text-case: none;
$font-large-font-size: 18;
$font-large-text-decoration: none;
$font-large-font-family: Mulish;
$font-large-font-weight: 400;
$font-large-font-style: normal;
$font-large-font-stretch: normal;
$font-large-font-style-old: Regular;
$font-large-letter-spacing: 0;
$font-large-line-height: 27;
$font-large-paragraph-indent: 0;
$font-large-paragraph-spacing: 0;
$font-large-text-case: none;
$font-medium-bold-font-size: 16;
$font-medium-bold-text-decoration: none;
$font-medium-bold-font-family: Mulish;
$font-medium-bold-font-weight: 700;
$font-medium-bold-font-style: normal;
$font-medium-bold-font-stretch: normal;
$font-medium-bold-font-style-old: Bold;
$font-medium-bold-letter-spacing: 0;
$font-medium-bold-line-height: 24;
$font-medium-bold-paragraph-indent: 0;
$font-medium-bold-paragraph-spacing: 0;
$font-medium-bold-text-case: none;
$font-medium-font-size: 16;
$font-medium-text-decoration: none;
$font-medium-font-family: Mulish;
$font-medium-font-weight: 400;
$font-medium-font-style: normal;
$font-medium-font-stretch: normal;
$font-medium-font-style-old: Regular;
$font-medium-letter-spacing: 0;
$font-medium-line-height: 24;
$font-medium-paragraph-indent: 0;
$font-medium-paragraph-spacing: 0;
$font-medium-text-case: none;
$font-small-bold-font-size: 14;
$font-small-bold-text-decoration: none;
$font-small-bold-font-family: Mulish;
$font-small-bold-font-weight: 700;
$font-small-bold-font-style: normal;
$font-small-bold-font-stretch: normal;
$font-small-bold-font-style-old: Bold;
$font-small-bold-letter-spacing: 0;
$font-small-bold-line-height: 24;
$font-small-bold-paragraph-indent: 0;
$font-small-bold-paragraph-spacing: 0;
$font-small-bold-text-case: none;
$font-small-font-size: 14;
$font-small-text-decoration: none;
$font-small-font-family: Mulish;
$font-small-font-weight: 400;
$font-small-font-style: normal;
$font-small-font-stretch: normal;
$font-small-font-style-old: Regular;
$font-small-letter-spacing: 0;
$font-small-line-height: 20;
$font-small-paragraph-indent: 0;
$font-small-paragraph-spacing: 0;
$font-small-text-case: none;
$font-xsmall-bold-font-size: 14;
$font-xsmall-bold-text-decoration: none;
$font-xsmall-bold-font-family: Mulish;
$font-xsmall-bold-font-weight: 700;
$font-xsmall-bold-font-style: normal;
$font-xsmall-bold-font-stretch: normal;
$font-xsmall-bold-font-style-old: Bold;
$font-xsmall-bold-letter-spacing: 0.75;
$font-xsmall-bold-line-height: 24;
$font-xsmall-bold-paragraph-indent: 0;
$font-xsmall-bold-paragraph-spacing: 0;
$font-xsmall-bold-text-case: none;
$font-xsmall-font-size: 14;
$font-xsmall-text-decoration: none;
$font-xsmall-font-family: Mulish;
$font-xsmall-font-weight: 400;
$font-xsmall-font-style: normal;
$font-xsmall-font-stretch: normal;
$font-xsmall-font-style-old: Regular;
$font-xsmall-letter-spacing: 0;
$font-xsmall-line-height: 18;
$font-xsmall-paragraph-indent: 0;
$font-xsmall-paragraph-spacing: 0;
$font-xsmall-text-case: none;
$font-xxsmall-bold-font-size: 10;
$font-xxsmall-bold-text-decoration: none;
$font-xxsmall-bold-font-family: Mulish;
$font-xxsmall-bold-font-weight: 700;
$font-xxsmall-bold-font-style: normal;
$font-xxsmall-bold-font-stretch: normal;
$font-xxsmall-bold-font-style-old: Bold;
$font-xxsmall-bold-letter-spacing: 0.75;
$font-xxsmall-bold-line-height: 24;
$font-xxsmall-bold-paragraph-indent: 0;
$font-xxsmall-bold-paragraph-spacing: 0;
$font-xxsmall-bold-text-case: none;
$font-xxsmall-font-size: 10;
$font-xxsmall-text-decoration: none;
$font-xxsmall-font-family: Mulish;
$font-xxsmall-font-weight: 400;
$font-xxsmall-font-style: normal;
$font-xxsmall-font-stretch: normal;
$font-xxsmall-font-style-old: Regular;
$font-xxsmall-letter-spacing: 0.75;
$font-xxsmall-line-height: 24;
$font-xxsmall-paragraph-indent: 0;
$font-xxsmall-paragraph-spacing: 0;
$font-xxsmall-text-case: none;
$font-button-lead-font-size: 20;
$font-button-lead-text-decoration: none;
$font-button-lead-font-family: Mulish;
$font-button-lead-font-weight: 700;
$font-button-lead-font-style: normal;
$font-button-lead-font-stretch: normal;
$font-button-lead-font-style-old: Bold;
$font-button-lead-letter-spacing: 0.75;
$font-button-lead-line-height: 32;
$font-button-lead-paragraph-indent: 0;
$font-button-lead-paragraph-spacing: 0;
$font-button-lead-text-case: none;
$font-button-font-size: 16;
$font-button-text-decoration: none;
$font-button-font-family: Mulish;
$font-button-font-weight: 700;
$font-button-font-style: normal;
$font-button-font-stretch: normal;
$font-button-font-style-old: Bold;
$font-button-letter-spacing: 0.75;
$font-button-line-height: 28;
$font-button-paragraph-indent: 0;
$font-button-paragraph-spacing: 0;
$font-button-text-case: none;
$font-button-small-font-size: 14;
$font-button-small-text-decoration: none;
$font-button-small-font-family: Mulish;
$font-button-small-font-weight: 700;
$font-button-small-font-style: normal;
$font-button-small-font-stretch: normal;
$font-button-small-font-style-old: Bold;
$font-button-small-letter-spacing: 0.75;
$font-button-small-line-height: 26;
$font-button-small-paragraph-indent: 0;
$font-button-small-paragraph-spacing: 0;
$font-button-small-text-case: none;
$font-button-xsmall-font-size: 14;
$font-button-xsmall-text-decoration: none;
$font-button-xsmall-font-family: Mulish;
$font-button-xsmall-font-weight: 700;
$font-button-xsmall-font-style: normal;
$font-button-xsmall-font-stretch: normal;
$font-button-xsmall-font-style-old: Bold;
$font-button-xsmall-letter-spacing: 0.75;
$font-button-xsmall-line-height: 22;
$font-button-xsmall-paragraph-indent: 0;
$font-button-xsmall-paragraph-spacing: 0;
$font-button-xsmall-text-case: none;
$font-desktop-display-1-font-size: 53;
$font-desktop-display-1-text-decoration: none;
$font-desktop-display-1-font-family: Jost;
$font-desktop-display-1-font-weight: 600;
$font-desktop-display-1-font-style: normal;
$font-desktop-display-1-font-stretch: normal;
$font-desktop-display-1-font-style-old: SemiBold;
$font-desktop-display-1-letter-spacing: 0;
$font-desktop-display-1-line-height: 120;
$font-desktop-display-1-paragraph-indent: 0;
$font-desktop-display-1-paragraph-spacing: 0;
$font-desktop-display-1-text-case: none;
$font-desktop-display-2-font-size: 68;
$font-desktop-display-2-text-decoration: none;
$font-desktop-display-2-font-family: Jost;
$font-desktop-display-2-font-weight: 600;
$font-desktop-display-2-font-style: normal;
$font-desktop-display-2-font-stretch: normal;
$font-desktop-display-2-font-style-old: SemiBold;
$font-desktop-display-2-letter-spacing: 0;
$font-desktop-display-2-line-height: 72;
$font-desktop-display-2-paragraph-indent: 0;
$font-desktop-display-2-paragraph-spacing: 0;
$font-desktop-display-2-text-case: none;
$font-desktop-h1-font-size: 44;
$font-desktop-h1-text-decoration: none;
$font-desktop-h1-font-family: Jost;
$font-desktop-h1-font-weight: 600;
$font-desktop-h1-font-style: normal;
$font-desktop-h1-font-stretch: normal;
$font-desktop-h1-font-style-old: SemiBold;
$font-desktop-h1-letter-spacing: 0;
$font-desktop-h1-line-height: 48;
$font-desktop-h1-paragraph-indent: 0;
$font-desktop-h1-paragraph-spacing: 0;
$font-desktop-h1-text-case: none;
$font-desktop-h2-font-size: 39;
$font-desktop-h2-text-decoration: none;
$font-desktop-h2-font-family: Jost;
$font-desktop-h2-font-weight: 600;
$font-desktop-h2-font-style: normal;
$font-desktop-h2-font-stretch: normal;
$font-desktop-h2-font-style-old: SemiBold;
$font-desktop-h2-letter-spacing: 0;
$font-desktop-h2-line-height: 44;
$font-desktop-h2-paragraph-indent: 0;
$font-desktop-h2-paragraph-spacing: 0;
$font-desktop-h2-text-case: none;
$font-desktop-h3-font-size: 31;
$font-desktop-h3-text-decoration: none;
$font-desktop-h3-font-family: Jost;
$font-desktop-h3-font-weight: 600;
$font-desktop-h3-font-style: normal;
$font-desktop-h3-font-stretch: normal;
$font-desktop-h3-font-style-old: SemiBold;
$font-desktop-h3-letter-spacing: 0;
$font-desktop-h3-line-height: 36;
$font-desktop-h3-paragraph-indent: 0;
$font-desktop-h3-paragraph-spacing: 0;
$font-desktop-h3-text-case: none;
$font-desktop-h4-font-size: 25;
$font-desktop-h4-text-decoration: none;
$font-desktop-h4-font-family: Jost;
$font-desktop-h4-font-weight: 600;
$font-desktop-h4-font-style: normal;
$font-desktop-h4-font-stretch: normal;
$font-desktop-h4-font-style-old: SemiBold;
$font-desktop-h4-letter-spacing: 0;
$font-desktop-h4-line-height: 32;
$font-desktop-h4-paragraph-indent: 0;
$font-desktop-h4-paragraph-spacing: 0;
$font-desktop-h4-text-case: none;
$font-desktop-h5-font-size: 25;
$font-desktop-h5-text-decoration: none;
$font-desktop-h5-font-family: Jost;
$font-desktop-h5-font-weight: 600;
$font-desktop-h5-font-style: normal;
$font-desktop-h5-font-stretch: normal;
$font-desktop-h5-font-style-old: SemiBold;
$font-desktop-h5-letter-spacing: 0;
$font-desktop-h5-line-height: 31;
$font-desktop-h5-paragraph-indent: 0;
$font-desktop-h5-paragraph-spacing: 0;
$font-desktop-h5-text-case: none;
$font-desktop-h6-font-size: 19;
$font-desktop-h6-text-decoration: none;
$font-desktop-h6-font-family: Jost;
$font-desktop-h6-font-weight: 600;
$font-desktop-h6-font-style: normal;
$font-desktop-h6-font-stretch: normal;
$font-desktop-h6-font-style-old: SemiBold;
$font-desktop-h6-letter-spacing: 0;
$font-desktop-h6-line-height: 25;
$font-desktop-h6-paragraph-indent: 0;
$font-desktop-h6-paragraph-spacing: 0;
$font-desktop-h6-text-case: none;
$font-mobile-display-1-font-size: 61;
$font-mobile-display-1-text-decoration: none;
$font-mobile-display-1-font-family: Jost;
$font-mobile-display-1-font-weight: 600;
$font-mobile-display-1-font-style: normal;
$font-mobile-display-1-font-stretch: normal;
$font-mobile-display-1-font-style-old: SemiBold;
$font-mobile-display-1-letter-spacing: 0;
$font-mobile-display-1-line-height: 64;
$font-mobile-display-1-paragraph-indent: 0;
$font-mobile-display-1-paragraph-spacing: 0;
$font-mobile-display-1-text-case: none;
$font-mobile-display-2-font-size: 51;
$font-mobile-display-2-text-decoration: none;
$font-mobile-display-2-font-family: Jost;
$font-mobile-display-2-font-weight: 600;
$font-mobile-display-2-font-style: normal;
$font-mobile-display-2-font-stretch: normal;
$font-mobile-display-2-font-style-old: SemiBold;
$font-mobile-display-2-letter-spacing: 0;
$font-mobile-display-2-line-height: 54;
$font-mobile-display-2-paragraph-indent: 0;
$font-mobile-display-2-paragraph-spacing: 0;
$font-mobile-display-2-text-case: none;
$font-mobile-h1-font-size: 43;
$font-mobile-h1-text-decoration: none;
$font-mobile-h1-font-family: Jost;
$font-mobile-h1-font-weight: 600;
$font-mobile-h1-font-style: normal;
$font-mobile-h1-font-stretch: normal;
$font-mobile-h1-font-style-old: SemiBold;
$font-mobile-h1-letter-spacing: 0;
$font-mobile-h1-line-height: 46;
$font-mobile-h1-paragraph-indent: 0;
$font-mobile-h1-paragraph-spacing: 0;
$font-mobile-h1-text-case: none;
$font-mobile-h2-font-size: 36;
$font-mobile-h2-text-decoration: none;
$font-mobile-h2-font-family: Jost;
$font-mobile-h2-font-weight: 600;
$font-mobile-h2-font-style: normal;
$font-mobile-h2-font-stretch: normal;
$font-mobile-h2-font-style-old: SemiBold;
$font-mobile-h2-letter-spacing: 0;
$font-mobile-h2-line-height: 40;
$font-mobile-h2-paragraph-indent: 0;
$font-mobile-h2-paragraph-spacing: 0;
$font-mobile-h2-text-case: none;
$font-mobile-h3-font-size: 30;
$font-mobile-h3-text-decoration: none;
$font-mobile-h3-font-family: Jost;
$font-mobile-h3-font-weight: 600;
$font-mobile-h3-font-style: normal;
$font-mobile-h3-font-stretch: normal;
$font-mobile-h3-font-style-old: SemiBold;
$font-mobile-h3-letter-spacing: 0;
$font-mobile-h3-line-height: 34;
$font-mobile-h3-paragraph-indent: 0;
$font-mobile-h3-paragraph-spacing: 0;
$font-mobile-h3-text-case: none;
$font-mobile-h4-font-size: 25;
$font-mobile-h4-text-decoration: none;
$font-mobile-h4-font-family: Jost;
$font-mobile-h4-font-weight: 600;
$font-mobile-h4-font-style: normal;
$font-mobile-h4-font-stretch: normal;
$font-mobile-h4-font-style-old: SemiBold;
$font-mobile-h4-letter-spacing: 0;
$font-mobile-h4-line-height: 28;
$font-mobile-h4-paragraph-indent: 0;
$font-mobile-h4-paragraph-spacing: 0;
$font-mobile-h4-text-case: none;
$font-mobile-h5-font-size: 21;
$font-mobile-h5-text-decoration: none;
$font-mobile-h5-font-family: Jost;
$font-mobile-h5-font-weight: 600;
$font-mobile-h5-font-style: normal;
$font-mobile-h5-font-stretch: normal;
$font-mobile-h5-font-style-old: SemiBold;
$font-mobile-h5-letter-spacing: 0;
$font-mobile-h5-line-height: 27;
$font-mobile-h5-paragraph-indent: 0;
$font-mobile-h5-paragraph-spacing: 0;
$font-mobile-h5-text-case: none;
$font-mobile-h6-font-size: 18;
$font-mobile-h6-text-decoration: none;
$font-mobile-h6-font-family: Jost;
$font-mobile-h6-font-weight: 600;
$font-mobile-h6-font-style: normal;
$font-mobile-h6-font-stretch: normal;
$font-mobile-h6-font-style-old: SemiBold;
$font-mobile-h6-letter-spacing: 0;
$font-mobile-h6-line-height: 22;
$font-mobile-h6-paragraph-indent: 0;
$font-mobile-h6-paragraph-spacing: 0;
$font-mobile-h6-text-case: none;
$effect-card-shadow-type: dropShadow;
$effect-card-shadow-radius: 20;
$effect-card-shadow-color: rgba(0, 0, 0, 0.05);
$effect-card-shadow-offset-x: 0;
$effect-card-shadow-offset-y: 0;
$effect-card-shadow-spread: 0;
