/* -------------------------------------------------------------
//  Highlight
// -----------------------------------------------------------*/
.highlight_wrapper {
  background-size: 0;
  position: relative;

  .highlight__background {
    display: none;
  }
  .highlight__left {
    padding: 25px 25px 50px 25px;
  }
  .highlight__right {
    background-color: $yellow;
    display: flex;
    flex-wrap: wrap;
  }
  .highlight__item {
    background-color: $yellow;
    color: #fff;
    padding: 30px 0 25px 0;
    text-align: center;
    width: calc(50% - 1px);

    p {
      font-size: 1rem;
      margin-top: 12px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .highlight_copy {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .highlight__image {
    margin-bottom: 0;
    width: 115px;
  }
}

@media only screen and (min-width: 992px) {
  .highlight_wrapper {
    display: flex;

    .highlight__left {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }
    .highlight__right {
      width: 50%;
    }
  }
}
@media only screen and (min-width: 1400px) {
  .highlight_wrapper {
    .highlight__background {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -340px;
      img {
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }

    .highlight__left {
      padding-left: 200px;
      width: calc(50% - 200px);
      z-index: 10;
    }
  }
  .highlight_wrapper .highlight__item {
    padding: 60px 0 45px 0;
    width: calc(100% / 3 - 2px);
  }
}
@media only screen and (min-width: 1700px) {
  .highlight_wrapper {
    .highlight__background {
      left: -190px;
    }
    .highlight__left {
      padding-left: 350px;
      width: calc(50% - 350px);
    }
  }
}

/* -------------------------------------------------------------
//  Panoramaimage
// -----------------------------------------------------------*/
.panoramaimage {
  margin-bottom: $section-margin-bottom;
  img {
    width: 100%;
  }
}
