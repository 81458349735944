/* -------------------------------------------------------------
//  News
// -----------------------------------------------------------*/
.newslist {
  margin: 0;
  padding: 0;
}
.newslist__item {
  background-color: $white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  margin: 0;
  margin-bottom: 32px;
  padding: 32px;
  list-style-type: none;
  .row {
    width: 100%;
  }

  p {
    margin-top: 0;
  }
}
.newslist__headline {
  margin-top: 0;
  margin-bottom: 8px;
}
.newslist__image {
  height: 120px;
  margin-right: 30px;
  width: 160px;
}
.newsdetail__headline {
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .newslist__item {
    display: flex;
  }
}
